var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.contract)?_c('spinner',{attrs:{"large":"","center":""}}):_c('div',{staticClass:"checkout-contract"},[_c('h1',{staticClass:"text-center mb-10"},[_vm._v(_vm._s(_vm.$t("checkout.contract")))]),_c('ContractsViewer',{attrs:{"contract":_vm.contract},on:{"scrolled":function($event){_vm.scrolled = true}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('confirm-dialog',{attrs:{"buttonAttrs":{
            disabled: !_vm.contract,
            color: 'secondary',
            class: 'px-10',
            loading: _vm.$store.getters['orders/loading'].contractResponse,
          },"buttonText":_vm.$t('buttons.deny'),"title":_vm.$t('checkout.contractDenyTitle'),"text":_vm.$t('checkout.contractDenyText')},on:{"confirm":function($event){return _vm.respond(false)}}}),_c('confirm-dialog',{attrs:{"buttonAttrs":{
            disabled: !_vm.contract || !_vm.scrolled,
            color: 'primary',
            class: 'ml-5 px-10',
            loading: _vm.$store.getters['orders/loading'].contractResponse,
          },"buttonText":_vm.$t('buttons.accept'),"title":_vm.$t('checkout.contractAcceptTitle'),"text":_vm.$t('checkout.contractAcceptText')},on:{"confirm":function($event){return _vm.respond(true)}}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }